<template>
<div>
<BasePageBreadCrumb :pages="pages" title="perfil" :menu="menu"/>
  <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Senha atual </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-model="old_password">
                                            </div>
                                        </div>
                                        <div class="col-12 pt-4 pb-2">
                                            <h4 class="card-title">Senha de acesso</h4>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Nova senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-model="password" v-validate="'required|min:8'" ref="password" name="password" :class="{'is-invalid': errors.has('password')}">
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Confirmar nova senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-validate="'required|confirmed:password'" name="confirm_password" :class="{'is-invalid': errors.has('confirm_password')}">
                                            </div>
                                            <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark">Cancelar</button>
                                        <button type="submit" class="btn btn-info ml-2">Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</div>
</template>
<script>
import QAdminService from "@/services/resources/QAdminService";
const service = QAdminService.build();

export default {
   data(){
       return{
           client:{
               old_password: null,
               password: null,
           },
           id: null,
           pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'perfil',
                    to: 'Profile'
                }
            ],
            menu:[],
       }
   },
   computed:{
       user_id(){
           return this.$store.getters.getProfile.id;
       }
   },
   methods:{
       save(){

        this.$validator.validateAll().then((result) => {
            if (result ) {
                let client = Object.assign(this.client)
                client.id = this.user_id;

                service
                .update(client)
                .then(resp => {
                this.$bvToast.toast('Sua senha foi atualizada com sucesso!', {
                    title: 'cliente atualizado',
                    autoHideDelay: 5000,
                    type: 'success'
                    })
                })
                .catch(err => {
                    this.$bvToast.toast('A senha antiga está incorreta.', {
                        title: 'Criação do cliente',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                })

          
            }
        });
       },
   },
}
</script>